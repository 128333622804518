import List from "./List/List";
import Update from "./Update/Update";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import Details from "./Details/Details";
import Menubar from './components/Menubar/Menubar';
import Home from './pages/Home';
import New from './pages/New';
import Templates from './pages/Templates';
import Tips from './pages/Tips';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [creativeId, setCreativeId] = useState("");
  return (

    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <main>
    <>  
     <Menubar />
     
      <Routes>
      <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} /> 
          <Route path='/new' element={<New />} />
          <Route path='/templates' element={<Templates/>} />             
          <Route path='/tips' element={<Tips/>} />    
        <Route
          path="/"
          element={
            <List
              setCreativeId={setCreativeId}
              creativeId={creativeId}
            />
          }
        />
        <Route path="/creatives/:id" element={<Details />} />
        <Route path="/update-creative/:id" element={<Update />} />
      </Routes>
    </></main>
    </ThemeProvider>
  );
}

export default App;