import styled from 'styled-components'
import SignIn from "../components/Menubar/SignIn";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const NewText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 20vh;
`

function New() {
   
    return (
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <>  
     
     <NewText>Connectez-vous...</NewText>
     <SignIn />

       
      </></ThemeProvider>
    );   
  }
  
export default New