import styled from 'styled-components'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const TemplatesText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 20vh;
`

function Templates() {
   
    return (
      <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <>  
     
     <TemplatesText>Exemples de templates</TemplatesText>
 

     <TemplatesText>DEV 1 - Liste / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

     <TemplatesText>DEV 1 - Fiche technique / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

     <TemplatesText>DEV 1 - Formulaire de mise à jour / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

     <TemplatesText>DEV 2 - Gestion des utilisateurs / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

     <TemplatesText>DEV 2 - Formulaire de création d'une nouvelle bannière / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

     <TemplatesText>DEV 2 - Gestion de l'intégration / Exemple : intégration en cours au 13 avril 2023</TemplatesText>

       
      </></ThemeProvider>
    );   
  }
  
export default Templates