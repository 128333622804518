import styled from 'styled-components'
import AccTips from '../components/Menubar/AccTips'

const TipsText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 20vh;
`

function Tips() {
    return (
      <>  
     
     <TipsText>Précisions et éléments techniques</TipsText>
     <AccTips />
       
      </>
    );
  }
  
export default Tips