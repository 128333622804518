import List from "../List/List";
import Update from "../Update/Update";
import Details from "../Details/Details";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import styled from 'styled-components'
import Container from '@mui/material/Container';

const HomeText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 20vh;
`

const DevText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 20vh;
`

function Home() {
    const [creativeId, setCreativeId] = useState("");
    return (
      <>  
     
     <HomeText>Anciennes bannières selon les mockups proposés </HomeText>

     <Container component="main" maxWidth="xs">
     <DevText>13 avril 2023 / Bonjour Clément et Thomas... La configuration ci-dessous, à peine touchée, est plutôt là pour nous rappeler ce qui était demandé. Mais c'est dans la rubrique Nouvelles templates (voir Menus) que vous trouverez l'App. optimisée. Une fois que nous serons tous les trois satisfaits, je n'aurai plus qu'à gither.</DevText></Container>
        <Routes>
          <Route
            path="/"
            element={
              <List
                setCreativeId={setCreativeId}
                creativeId={creativeId}
              />
            }
          />
          <Route path="/creatives/:id" element={<Details />} />
          <Route path="/update-creative/:id" element={<Update />} />
        </Routes>
      </>
    );
  }
  
export default Home