import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function SimpleAccordion() {
    return (
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
    <div>
    <Container component="main" maxWidth="xs">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>État du développement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Au 13 avril 2023 :
          </Typography>
          <Typography>
          - Création en cours d'une API JSon séparée pour davantage de contrôle.
          </Typography>
          <Typography>
          - De nouvelles fonctionnalités sont actuellement en cours d'intégration.
          </Typography>
          <Typography>
          - Tests finaux à venir...
          </Typography>
          <Typography>
          - Dépôt sur Git.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Environnements techniques</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           - React / Typescript / Vite / Yarn
          </Typography>
          <Typography>
           - React Router / React Query / Axios
          </Typography>
          <Typography>
           - Material UI
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Tests</Typography>
        </AccordionSummary>
      </Accordion>
      </Container>
    </div></ThemeProvider>
  );
}